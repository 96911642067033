<script>
import { IconAccount, IconKey, IconNewTab } from '@/icons'
import { mapState } from 'vuex'
import { ADD_NOTIFICATION } from '@/store/actions.type'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
var Nanobar = require('../../../node_modules/nanobar/nanobar')
const nanobar = new Nanobar()

export default {
  name: 'Dashboard',

  data() {
    return {
      loading: true,
    }
  },

  components: {
    IconAccount,
    IconKey,
    IconNewTab,
    VueSlickCarousel,
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapState('shop', ['currentShop']),

    showMultiLocationCard() {
      const { is_multi_locations, type, platform } = this.currentShop
      return is_multi_locations !== "1" && type === 'destination' && platform === 'shopify'
    },
  },

  async created() {
    try {
      nanobar.go(this.randomNumber(20, 90))
      this.loading = true
      await this.$store.dispatch(`shop/init`)
      await this.$store.dispatch(`plan/init`, this.user.id)
      this.loading = false
      nanobar.go(100)
    } catch(error) {
      this.loading = false
      nanobar.go(100)
    }
  },

  methods: {
    randomNumber(min, max){
      const r = Math.random()*(max-min) + min
      return Math.floor(r)
    },

    copyStoreIdentifier() {
      let copyText = document.getElementById('shop-identifier').textContent
      navigator.clipboard.writeText(copyText).then(() => {
        this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
          notification: {
            id: 'N' + (Math.floor(Math.random() * 100000000)),
            position: "bottom-left",
            type: 'success',
            body: 'Unique store key copied to clipboard',
            state: 0,
            length: 3000,
            data: null,
          },
        });
      });
    },
  }
}
</script>

<template>
  <article class="v2">
    <section class="dashboard" v-if="!loading">

      <div>
        <h4 class="m-b-3 m-t-2">What's new</h4>
        <VueSlickCarousel :arrows="false" :dots="true" :autoplay="true" :autoplaySpeed="5000">
          <div class="dashboard-update d-flex">
            <div>
              <h1>Sell in more places</h1>
              <p>Use Syncio to connect to Woo <br> Commerce based stores.</p>
              <p>To start sharing inventory, <br> simply connect as you normally <br> would on the Stores page.</p>
              <a href="https://help.syncio.co/en/articles/7171444-woocommerce-overview" target="_blank" class="btn btn-learn-more d-inline-block">Learn more</a>
            </div>
            <img src="@/assets/images/woo-shopify.svg" class="dashboard-image woo" alt="Connect">
            <ul class="list-unstyled">
              <li class="d-flex">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_22_3352)">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="#0E3B4D"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_22_3352">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span>Seamlessly sync inventory between Woo and Shopify</span>
              </li>
              <li class="d-flex">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_22_3352)">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="#0E3B4D"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_22_3352">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span>It works just like with Shopify to Shopify</span>
              </li>
              <li class="d-flex">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_22_3352)">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="#0E3B4D"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_22_3352">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span>Less manual work. More time to grow your business.</span>
              </li>
            </ul>
          </div>
          <div class="dashboard-update d-flex">
            <div>
              <h1>Boost sales with your <br> perfect partner</h1>
              <p>Syncio Marketplace is coming soon.</p>
              <p>Find new products and form long <br> term connections with reliable, like-<br>minded partners.</p>
              <router-link to="/marketplace" class="btn btn-learn-more d-inline-block m-t-3">
                Learn more
              </router-link>
            </div>
            <img src="@/assets/images/marketplace.svg" class="dashboard-image marketplace-img" alt="Marketplace">
          </div>
          <div class="dashboard-update d-flex" v-if="currentShop.type === 'destination'">
            <div>
              <h1>Sync stock to multiple locations</h1>
              <p>Multi-location allows you to choose where <br> stock syncs to in your destination store.*</p>
              <p>To use Multi-location, activate the <br> toggle on the Stores page.</p>
              <p class="small-text">*Only available for Shopify stores</p>
              <a href="https://help.syncio.co/en/articles/5842693-multilocations-for-destination-stores" class="btn btn-learn-more d-inline-block" target="_blank">Learn more</a>
            </div>
            <img src="@/assets/images/multilocation.svg" class="dashboard-image multilocation" alt="Multilocation">
          </div>
        </VueSlickCarousel>
      </div>

      <h4 class="m-b-3 m-t-8">The essentials</h4>
      <aside class="dashboard-essentials d-flex justify-between">
        <div class="card">
          <IconAccount />
          <h5 class="p-t-1">Your account</h5>
          <ul class="list list-small">
            <li>
              <router-link to="/settings/account/general" class="link link-underline">Account settings</router-link>
              <p class="last">Manage account and notification settings</p>
            </li>
            <li v-if="currentShop.type === 'destination'">
              <router-link to="/account/billing" class="link link-underline">Plan and billing</router-link>
              <p class="last">Manage your plan and billing details</p>
            </li>
          </ul>
        </div>

        <div class="card">
          <IconKey />
          <h5 class="p-t-1">Unique store key</h5>
          <p v-if="currentShop.type === 'source'">Share this with <strong>Destination stores</strong> <br> so they can import your products</p>
          <p v-else>Share this with <strong>Source stores</strong> <br> so you can import products to your store.</p>
          <p class="last p-t-1">
            <strong class="m-r-1" id="shop-identifier">{{ currentShop && currentShop.identifier }}</strong>
            <a href="javascript:void(0);" class="link" @click="copyStoreIdentifier">Copy</a>
          </p>
        </div>

        <div class="card">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2H18V14H3.17L2 15.17V2ZM2 0C0.9 0 0.00999999 0.9 0.00999999 2L0 20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0H2ZM4 10H12V12H4V10ZM4 7H16V9H4V7ZM4 4H16V6H4V4Z" fill="#0E3B4D"/>
          </svg>
          <h5 class="p-t-1">Have your say</h5>
          <ul class="list list-small">
            <li>
              <a href="https://syncio.canny.io/" class="link link-underline" target="_blank">
                Feature requests
                <IconNewTab />
              </a>
              <p class="last">Got an idea? Submit here</p>
            </li>
            <li>
              <a href="https://forms.gle/arEeh9yJHsrDM8cX9" class="link link-underline" target="_blank">
                Let us know what you think
                <IconNewTab />
              </a>
              <p class="last">Answer a short 3 minute survey to help us improve Syncio for you</p>
            </li>
          </ul>
        </div>
      </aside>

      <h4 class="m-b-3 p-t-3">Getting started with Syncio</h4>
      <aside class="dashboard-right">
        <template>
          <div class="card">
            <h5 class="p-b-1">Learn the basics</h5>
            <ul class="list list-underline">
              <li>
                <a href="http://help.syncio.co/en/articles/6116999-quick-start" target="_blank" class="link link-underline">
                  Quick start guide
                  <IconNewTab />
                </a>
              </li>
              <li>
                <a href="http://help.syncio.co/en/articles/5596700-connecting-to-a-store" class="link link-underline" target="_blank">
                  Connecting to a store
                  <IconNewTab />
                </a>
              </li>
              <li>
                <a href="http://help.syncio.co/en/collections/1906212-syncing-products" class="link link-underline" target="_blank">
                  Syncing products
                  <IconNewTab />
                </a>
              </li>
              <li>
                <a href="http://help.syncio.co/en/articles/3284137-best-practices-healthy-syncing" class="link link-underline" target="_blank">
                  Best practices and healthy syncing
                  <IconNewTab />
                </a>
              </li>
            </ul>
            <div class="text-center p-t-3 p-b-1">
              <a href="http://help.syncio.co/en/" class="link link-underline" target="_blank">
                Visit the Help center for more
                <IconNewTab />
              </a>
            </div>
          </div>
        </template>
      </aside>
    </section>
  </article>
</template>

<style scoped>
  .small-text {
    font-size: 14px !important;
  }
</style>
